const MAIL_COMPLIANCE = 'compliance-meldungen@breuninger.de';
const URL_REQUEST = 'http://localhost:3000';
const URL_COMPANY = 'https://www.e-breuninger.de/';
const URL_PRIVACY_POLICY = '/Datenschutzinformation_Hinweisgeberformular.pdf';
const URL_CODE_CONDUCT = 'https://www.e-breuninger.de/code-of-conduct';
const URL_PROCESS_DESCRIPTION = '/Verfahrensordnung_Hinweisgeberformular.pdf';
const URL_WHISTLEBLOWER_FORM = 'https://compliance-meldungen.e-breuninger.de/';
const URL_FEDERAL_OFFICE_OF_JUSTICE_REPORTING_OFFICE =
	'https://www.bundesjustizamt.de/DE/MeldestelledesBundes/MeldestelledesBundes_node.html';
const URL_BAFIN_REPORTING_OFFICE =
	'https://www.bafin.de/DE/DieBaFin/Hinweisgeberstelle/hinweisgeberstelle_node.html';
const URL_FEDERAL_CARTEL_OFFICE_REPORTING_OFFICE =
	'https://www.bundeskartellamt.de/DE/Kartellverbot/Hinweise_auf_Verstoesse/Hinweise_node.html';

const URL_IMPRINT = {
	CN: 'https://www.e-breuninger.de/cn/contact-legal/',
	CZ: 'https://www.breuninger.com/cz/service/imprint/',
	DE: 'https://www.e-breuninger.de/de/legal/impressum/',
	EN: 'https://www.e-breuninger.de/en/contact-legal/',
	FR: 'https://www.e-breuninger.de/en/contact-legal/',
	GR: 'https://www.e-breuninger.de/en/contact-legal/',
	IT: 'https://www.e-breuninger.de/en/contact-legal/',
	PL: 'https://www.breuninger.com/pl/service/imprint/',
	TR: 'https://www.e-breuninger.de/en/contact-legal/'
};

const URL_POLICY_HUMAN_RIGHTS = {
	DE: 'https://www.e-breuninger.de/Grundsatzerklaerung-zur-Achtung-der-Menschenrechte',
	EN: 'https://www.e-breuninger.de/policy-statement-on-the-observance-of-human-rights'
};

export const apiConfig = {
	MAIL_COMPLIANCE,
	URL_REQUEST,
	URL_COMPANY,
	URL_IMPRINT,
	URL_PRIVACY_POLICY,
	URL_CODE_CONDUCT,
	URL_PROCESS_DESCRIPTION,
	URL_POLICY_HUMAN_RIGHTS,
	URL_WHISTLEBLOWER_FORM,
	URL_FEDERAL_OFFICE_OF_JUSTICE_REPORTING_OFFICE,
	URL_BAFIN_REPORTING_OFFICE,
	URL_FEDERAL_CARTEL_OFFICE_REPORTING_OFFICE
};
